<template>
  <Popover :class="bem('')" :open="open" :modifiers="['sameWidth', 'noAutofocus']">
    <template #anchor>
      <AppInput
        v-bind="$props"
        @click="open = !open"
        @blur="blur"
        @keyup.space="show"
        @keyup.enter="show"
        @keyup.esc="hide"
        :autocomplete="false"
        :modelValue="getText(selectedItem)"
        readonly
      >
        <template #startIcon v-if="selectedItem?.icon || $slots.startIcon">
          <Icon :icon="selectedItem.icon" v-if="selectedItem?.icon" />
          <slot name="startIcon" v-else-if="$slots.startIcon" />
        </template>
        <template #endIcon v-if="$slots.endIcon">
          <slot name="endIcon" />
        </template>
        <template #endSlot>
          <Icon :class="bem('caret', { active: open })" icon="common/caret" color="current" inline :size="[7, 4]" />
        </template>
      </AppInput>
    </template>

    <div :class="bem('popover', { 'has-input': enableInput })" @mousedown.prevent v-if="open && items.length > 0">
      <div :class="bem('options')" role="listbox">
        <AppSelectItem
          v-for="(item, idx) in items"
          v-bind="typeof item === 'object' ? item : null"
          :key="idx"
          :class="bem('option')"
          :aria-selected="selectedIndex === idx ? 'true' : 'false'"
          @click="select(idx)"
        >
          <component :is="selectedIndex === idx ? 'b' : 'span'">{{ getText(item) }}</component>
        </AppSelectItem>
      </div>
      <AppInput
        v-if="enableInput"
        @blur="inputBlur"
        @mousedown="hasFocus = true"
        @focus="hasFocus = true"
        @update:modelValue="input"
        @keyup.enter="inputBlur"
        @keyup.esc="inputBlur"
        :class="bem('input', { 'has-focus': hasFocus })"
        :placeholder="inputPlaceholder"
        :modelValue="message"
        type="text"
      />
    </div>
  </Popover>
</template>

<script>
import AppInput from '@predicthq/vue3.components.app-input'
import AppSelectItem from './app-select-item.vue'
import Bem from '@predicthq/vue3.utils.mixin-bem'
import Icon from '@predicthq/vue3.components.icon'
import Popover from '@predicthq/vue3.components.popover'

export default {
  name: 'AppSelect',
  mixins: [Bem],
  components: {
    AppInput,
    Icon,
    AppSelectItem,
    Popover,
  },
  props: {
    /*
      Reuse app-input props
    */
    ...AppInput.props,

    /*
      v-model
    */
    modelValue: {
      type: [String, Number],
    },

    /*
     * Array of items to render
     */
    items: {
      type: Array,
      validator: function (value) {
        if (value.length === 0) return true

        if (typeof value[0] === 'object') {
          // Check if all items in the array have `text` prop
          return !value.some((i) => typeof i.text === 'undefined')
        }
        // Check if all items in the array are strings
        return typeof value[0] === 'string'
      },
    },
    enableInput: {
      type: Boolean,
      default: false,
    },
    inputPlaceholder: {
      type: String,
    },
  },
  data() {
    return {
      hasFocus: false,
      message: '',
      open: false,
      selectedIndex: -1,
    }
  },
  computed: {
    selectedItem() {
      // if no item has been selected from the list, it must be 'other'
      if (this.selectedIndex === -1) return this.message
      return this.items[this.selectedIndex]
    },
  },
  methods: {
    show() {
      this.open = true
    },
    hide() {
      this.open = false
    },
    getText(item) {
      return typeof item === 'object' ? item.text : item
    },
    getValue(item) {
      return item?.value ? item.value : this.getText(item)
    },
    select(idx) {
      if (this.selectedIndex === idx) {
        this.hide()
        return
      }

      this.message = ''
      this.selectedIndex = idx

      this.emitEvents()
      this.hide()
    },
    blur() {
      if (this.hasFocus) return
      if (this.open) this.emitEvents()
      this.$emit('blur')
      this.hide()
    },
    inputBlur() {
      this.hasFocus = false
      this.hide()
      this.emitEvents()
    },
    input(value) {
      if (!this.hasFocus) return

      this.message = value
      if (this.message) this.selectedIndex = -1
    },
    emitEvents() {
      this.$emit('update:modelValue', this.getValue(this.selectedItem))
      this.$emit('change', this.selectedItem)
    },
    // Update selected item in the input based on modelValue changes
    // This is required for when value is changed from outside of the component
    selectFromModel() {
      this.selectedIndex = this.items.findIndex((item) => this.getValue(item) === this.modelValue)

      // If value passed in is not one of the options, then it must be for the 'other' input
      if (this.selectedIndex === -1) {
        this.message = typeof this.modelValue === 'string' ? this.modelValue : ''
      }
    },
  },
  watch: {
    modelValue: {
      handler(val, oldVal) {
        if (val !== oldVal) this.selectFromModel()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@import './app-select.scss';
</style>
