<template>
  <div :class="bem('')">
    <div ref="anchorRef">
      <slot name="anchor" />
    </div>

    <div ref="contentRef" :class="bem('content')">
      <slot />
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue'
import bem from '@predicthq/vue3.utils.mixin-bem'
import usePopover from '@predicthq/vue3.use.use-popover'

/**
 * Displays a standard popover with a white background
 * and box shadow applied, anchored to an element provided
 * by the anchor slot.
 *
 * It is up to the consumer of the component to control the
 * open state of the popover via the `open` prop, which
 * gives flexibility for when it opens, and whether
 * clicking/focussing on the anchor should toggle
 * the popover or leave it open.
 *
 * Uses PopperJS (https://popper.js.org/) with a sensible
 * set of defaults. If more options
 * are needed (e.g. control over the popover position)
 * add them as props to this component.
 *
 * If you require popover behaviour without the DOM elements
 * or styling, usePopover Composition API function can be used.
 */
export default {
  name: 'AppPopover',
  mixins: [bem],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    modifiers: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const openRef = toRef(props, 'open')

    const { anchorRef, contentRef } = usePopover({
      openRef,
      onOutsideAction: (eventType) => {
        emit('outside-action', eventType)
      },
      modifiers: props.modifiers,
    })

    return {
      anchorRef,
      contentRef,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@predicthq/vue3.assets.scss/globals.scss';
.app-popover {
  &__content {
    background: $c-white;
    box-shadow: -20px 24px 64px rgba(0, 0, 0, 0.08);
    z-index: $z-popover;
    // Programmatic focus only - no outline needed.
    outline: none;
  }
}
</style>
