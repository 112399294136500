<template>
  <component :is="to ? 'BaseLink' : 'div'" :to="to" tabindex="0" role="option" :class="bem('')">
    <Typography :class="bem('text')" variant="p"><Icon v-if="icon" :icon="icon" /> <slot /></Typography>
  </component>
</template>
<script>
import BaseLink from '@predicthq/vue3.components.base-link'
import Bem from '@predicthq/vue3.utils.mixin-bem'
import Icon from '@predicthq/vue3.components.icon'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'AppSelectItem',
  mixins: [Bem],
  components: {
    BaseLink,
    Icon,
    Typography,
  },
  props: {
    icon: String,
    to: String,
  },
}
</script>
<style lang="scss" module src="./app-select-item.scss" />
